import createClient, { Middleware } from 'openapi-fetch';
import { type Ref } from 'vue';
import { toast } from 'vue-sonner';

import { type components } from '@/generated/configs';
import { usePlaygroundStore } from '@/store/playground.ts';

import { type paths as carrierGatewayPaths } from './carrier-gateway';
import { type paths as configsPaths } from './configs';
import { type paths as ftpPaths } from './ftp';
import { type paths as printingPaths } from './printing';
import { type paths as ratesPaths } from './rates';
import { type paths as shippingPaths } from './shipping';
import { type paths as shippingPathsNew } from './shipping-v3';
import createUntypedClient from './untypedClient.ts';

type AddPrefix<SS extends string, K> = K extends string ? `/${SS}${K}` : never;

enum ClientTypesEnum {
  Configs = 'configs',
  Shipping = 'shipping',
  FtpUploader = 'ftp-uploader',
  CarrierGateway = 'carrier-gateway',
  Ftp = 'ftp',
  Printing = 'printing',
  Rates = 'rates',
}

type ClientTypeKeys = ClientTypesEnum[number];

type TransformKeys<T extends object, SS extends ClientTypeKeys[number]> = {
  [K in keyof T as AddPrefix<SS, K>]: T[K];
};
type MergeTransformedTypes<T extends object> = {
  [K in keyof T]: T[K];
};
type TransformedConfigs = MergeTransformedTypes<
  TransformKeys<configsPaths, ClientTypesEnum.Configs>
>;
type TransformedShipping = MergeTransformedTypes<
  TransformKeys<shippingPaths, ClientTypesEnum.Shipping>
>;
type TransformedShippingNew = MergeTransformedTypes<
  TransformKeys<shippingPathsNew, ClientTypesEnum.Shipping>
>;
type TransformedFtp = MergeTransformedTypes<TransformKeys<ftpPaths, ClientTypesEnum.Ftp>>;
type TransformedCarrierGateway = MergeTransformedTypes<
  TransformKeys<carrierGatewayPaths, ClientTypesEnum.CarrierGateway>
>;
type TransformedPrinting = MergeTransformedTypes<
  TransformKeys<printingPaths, ClientTypesEnum.Printing>
>;
type TransFormedRates = MergeTransformedTypes<TransformKeys<ratesPaths, ClientTypesEnum.Rates>>;

type UnionToIntersection<U> = (U extends object | undefined ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never;
type MergedTransformedTypes = UnionToIntersection<
  | TransformedConfigs
  | TransformedShipping
  | TransformedShippingNew
  | TransformedCarrierGateway
  | TransformedFtp
  | TransformedPrinting
  | TransFormedRates
>;

export type CoreProblemDetails = components['schemas']['CoreProblemDetails'];

const playgroundMiddleware: Middleware = {
  async onRequest({ request }) {
    const playgroundStore = usePlaygroundStore();
    const newUrl = new URL(request.url);
    if (playgroundStore.isPlayground) {
      newUrl.searchParams.set('playground', playgroundStore.isPlayground ? 'true' : 'false');
    } else if (newUrl.searchParams.has('playground')) {
      newUrl.searchParams.delete('playground');
    } else {
      return undefined; // No need to modify the request in this case.
    }

    return new Request(newUrl, request);
  },
};

export const ApiClient = createClient<MergedTransformedTypes>({
  baseUrl: '/api/',
});
ApiClient.use(playgroundMiddleware);

export const untypedClient = createUntypedClient();

export function toProblemOrData<T>(
  res: any,
  problem: Ref<object | undefined>,
  data: Ref<T | undefined>
): void {
  if (isOk(res)) {
    data.value = res.data as T;
  } else {
    problem.value = toProblem(res);
  }
}

export function toProblem(res: FetchErrorResponse): CoreProblemDetails {
  if (res.error && Object.keys(res.error).length > 0) {
    return res.error;
  }

  return {
    title: res.response.statusText,
    status: res.response.status,
    type: `https://httpstatuses.io/${res.response.status}`,
    instance: res.response.url,
    errors: [],
  };
}

export function isOk(res: FetchErrorResponse): boolean {
  return res.response.ok;
}

export const checkResponseWithErrorRef = (
  res: FetchErrorResponse,
  methodString: string,
  error: Ref<CoreProblemDetails | undefined>,
  showSuccessPopup: boolean = false
): boolean => {
  const errors: CoreProblemDetails[] = [];
  checkResponse(res, methodString, errors, showSuccessPopup);
  if (errors.length > 0) {
    error.value = errors[0];
    return false;
  }
  return true;
};

export const checkResponse = (
  res: FetchErrorResponse,
  methodString: string,
  errors: CoreProblemDetails[] = [],
  showSuccessPopup: boolean = false
): boolean => {
  if (!isOk(res)) {
    const error = toProblem(res);
    errors.push(error);
    const errorSummary = `Failed to ${methodString}: ${error.title}`;
    console.warn(error);
    toast.error(errorSummary);
    return false;
  }

  const successSummary = `${methodString} successful`;
  if (showSuccessPopup) toast.success(successSummary);
  return true;
};

export const params = (
  pathParams: Record<string, string | undefined> = {},
  body: any | undefined = undefined
): any => {
  return {
    query: {},
    body: body,
    params: {
      path: pathParams,
    },
  };
};
